/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";
import { atom, useRecoilState } from "recoil";

import api, { riderId } from "../Api";

import BaseAddress from "../../models/BaseAddress";

const baseAddressesAtom = atom({
  key: "baseAddresses",
  default: null,
});

const baseAddressesAllAtom = atom({
  key: "baseAddressesAll",
  default: null,
});

export const useBaseAddressesStore = () => {
  const [baseAddresses, setBaseAddresses] = useRecoilState(baseAddressesAtom);
  const [baseAddressesAll, setBaseAddressesAll] =
    useRecoilState(baseAddressesAllAtom);

  const fetchAllById = useCallback(
    async ({ bookId, buildingDongNumber, address, addressRoad }) => {
      if (bookId) {
        const { noBuildingDong, withBuildingDong } = await api.get(
          `/deliveries/${bookId}/base-addresses/memos/all`,
        );

        let _currentBuildingDong = []; // 도로명 주소 일치 / 빌딩동 정보 일치
        let _withBuildingDongWithAddressRoad = []; // 도로명 주소 일치 / 빌딩동 정보 불일치
        let _withBuildingDongWithAddress = []; // 도로명 주소 불일치
        let _noBuildingDongWithAddressRoad = []; // 도로명 주소 일치
        let _noBuildingDongWithAddress = []; // 도로명 주소 불일치

        if (withBuildingDong?.length > 0) {
          withBuildingDong.forEach((el) => {
            if (
              `${el.buildingDongNumber}동` === buildingDongNumber &&
              addressRoad === el.baseAddress?.addressRoad
            ) {
              _currentBuildingDong.push(el);
            }
          });
        }

        if (noBuildingDong?.length > 0) {
          noBuildingDong.forEach((el) => {
            if (addressRoad === el.baseAddress?.addressRoad) {
              _noBuildingDongWithAddressRoad.push(el);
            } else if (address === el.baseAddress?.address) {
              _noBuildingDongWithAddress.push(el);
            }
          });
        }

        let baseAddresses = [];

        if (buildingDongNumber) {
          baseAddresses = [
            ..._currentBuildingDong,
            ..._withBuildingDongWithAddressRoad,
            ..._withBuildingDongWithAddress,
            ..._noBuildingDongWithAddressRoad,
            ..._noBuildingDongWithAddress,
          ];
        } else {
          baseAddresses = [
            ..._noBuildingDongWithAddressRoad,
            ..._noBuildingDongWithAddress,
            ..._currentBuildingDong,
            ..._withBuildingDongWithAddressRoad,
            ..._withBuildingDongWithAddress,
          ];
        }

        setBaseAddresses(baseAddresses.map((b) => new BaseAddress(b)));
        setBaseAddressesAll([...noBuildingDong, ...withBuildingDong]);
      }
    },
    [api.get, riderId],
  );

  const state = {
    baseAddresses,
    baseAddressesAll,
  };

  return { state, fetchAllById };
};
