/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import {
  useDeliveriesNewStore,
  useLoadingStore,
  useModalStore,
} from "../stores/hooks";

import DeliveriesNewAddressDetailModal from "../modals/DeliveryNewAddressDetail";

import { CORPS } from "../utils/corps";

function load(url, cb, err) {
  let element = document.createElement("script");

  element.async = true;
  element.onload = cb;
  element.onerror = err;

  element["src"] = url;
  document["head"].appendChild(element);
}

const CorpItem = ({ active, corp, onSelect }) => {
  const handleSelect = () => {
    onSelect(corp);
  };

  return (
    <div>
      <label className="block mb-10" onClick={handleSelect}>
        <input
          type="radio"
          name="corp"
          defaultChecked={!!active}
          className="mr-5"
        />
        {corp.displayName}
      </label>
    </div>
  );
};

const StepAddressComponent = ({ onCancel }) => {
  const { state, ...actions } = useDeliveriesNewStore();
  const { ...loadingActions } = useLoadingStore();
  const { openModal } = useModalStore();

  const drawPostcode = () => {
    const element_wrap = document.getElementById("wrap");

    new window.daum.Postcode({
      oncomplete: handleCompleteDaumPostcode,
      width: "100%",
      height: "100%",
    }).embed(element_wrap, { autoClose: false });
  };

  const handleCompleteDaumPostcode = (data) => {
    let roadAddress = data.roadAddress; // 도로명주소
    // let jibunAddress = data.jibunAddress || data.autoJibunAddress; // 지번주소
    let extraAddress = ""; // 참고항목 변수
    // let zonecode = data.zonecode;

    // 법정동명이 있을 경우 추가한다. (법정리는 제외)
    // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
    if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
      extraAddress += data.bname;
    }
    // 건물명이 있고, 공동주택일 경우 추가한다.
    if (data.buildingName !== "" && data.apartment === "Y") {
      extraAddress +=
        extraAddress !== "" ? ", " + data.buildingName : data.buildingName;
    }
    // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
    if (extraAddress !== "") {
      extraAddress = " (" + extraAddress + ")";
    }

    // const addressResult = {
    //   roadAddress: roadAddress + extraAddress,
    //   jibunAddress,
    //   zonecode,
    // };

    openModal(
      <DeliveriesNewAddressDetailModal
        key="delivery-new-address-detail-modal"
        address={roadAddress + extraAddress}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />,
    );
  };

  const onSubmit = async ({ address, addressDetail }) => {
    try {
      loadingActions.startLoading();

      const data = {
        ...state.body,
        receiverAddress: address,
        receiverAddressDetail: addressDetail,
      };

      await actions.create(data);

      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ created: true }),
        );
      }
    } catch (e) {
      window.alert(`배송 추가에 실패했습니다. ${e.message}`);
    }

    loadingActions.finishLoading();
  };

  useEffect(() => {
    load(
      "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js",
      drawPostcode,
    );
  }, []);

  return <div id="wrap" className="w-full h-screen" />;
};

const StepSelectCorpComponent = ({ onAfterSubmit, onCancel }) => {
  const { state, ...actions } = useDeliveriesNewStore();

  const { register, handleSubmit, reset, setValue, watch } = useForm();

  const [load, setLoad] = useState(false);

  useEffect(() => {
    reset({
      displayName: CORPS[0].displayName,
      senderName: CORPS[0].senderName,
      corpUser: CORPS[0].corpUser,
      spotCode: CORPS[0].spotCode,
      additionalFee: false,
    });

    setLoad(true);
  }, []);

  // 기업회원 선택
  const handleSelectCorp = (corp) => {
    setValue("displayName", corp.displayName);
    setValue("senderName", corp.senderName);
    setValue("corpUser", corp.corpUser);
    setValue("spotCode", corp.spotCode);
    setValue("senderNameCustom", corp.senderName);
  };

  // 송하인명(직접입력) 삭제
  const handleDeleteSenderNameCustom = () => {
    setValue("senderNameCustom", "");
  };

  // 사측주문번호 삭제
  const handleDeleteOrderIdFromCorp = () => {
    setValue("orderIdFromCorp", "");
  };

  const onSubmit = (data) => {
    if (data.corpUser !== "직접입력" && !data.orderIdFromCorp) {
      window.alert(
        "한진아마존 8~ 운송장번호/대한통운올리브영서울 5~ 운송장번호, 올리브영그외 Y~ 주문번호를 입력하고 확인 버튼을 터치해주십시오.",
      );
    } else if (data.corpUser === "직접입력" && !data.senderNameCustom) {
      window.alert("물품 송하인을 입력해주세요.");
    } else {
      actions.setBody({
        displayName: data.displayName,
        senderName: data.senderNameCustom || data.senderName,
        corpUser: data.corpUser,
        spotCode: data.spotCode,
        orderIdFromCorp: data.orderIdFromCorp,
        additionalFee: data.additionalFee,
      });

      onAfterSubmit();
    }
  };

  return (
    <div className="p-10">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-20">
          <h5 className="font-medium text-18 mb-10">물품 송하인 선택</h5>
          <div>
            {load &&
              CORPS.map((corp) => {
                return (
                  <CorpItem
                    key={corp.displayName}
                    active={corp.displayName === watch("displayName")}
                    corp={corp}
                    onSelect={handleSelectCorp}
                  />
                );
              })}
            <input name="displayName" {...register("displayName")} hidden />
            <input name="senderName" {...register("senderName")} hidden />
            <input name="corpUser" {...register("corpUser")} hidden />
            <input name="spotCode" {...register("spotCode")} hidden />
            {watch("corpUser") === "직접입력" && (
              <div className="bg-[#f6f6f6] p-5 flex justify-between items-center ml-10">
                <input
                  type="text"
                  className="bg-transparent max-w-[calc(100vw-120px)] flex-1"
                  {...register("senderNameCustom")}
                />
                <button
                  type="button"
                  className="text-[#999] p-5 w-50"
                  onClick={handleDeleteSenderNameCustom}
                >
                  삭제
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="mb-20">
          <h5 className="font-medium text-16 mb-10 text-orange">
            (추가정보필수입력) 한진아마존 운송장번호(8), 대한통운올리브영
            운송장번호(5), 올리브영그외 주문번호(Y) 스캔하여 입력
          </h5>
          <div>
            <div className="bg-[#f6f6f6] p-5 flex justify-between items-center ml-10">
              <input
                type="text"
                {...register("orderIdFromCorp")}
                className="bg-transparent max-w-[calc(100vw-120px)] flex-1"
              />
              <button
                type="button"
                className="text-[#999] p-5 w-50"
                onClick={handleDeleteOrderIdFromCorp}
              >
                삭제
              </button>
            </div>
          </div>
        </div>
        <div className="mb-20">
          <label className="mb-10 flex items-center justify-start">
            <input
              type="checkbox"
              name="additionalFee"
              className="checkbox-orange"
              {...register("additionalFee")}
            />
            <div className="icon-checkbox-orange mr-5" />
            추가운임
          </label>
        </div>
        <div className="flex justify-center items-center">
          <button type="button" className="flex-1 p-10" onClick={onCancel}>
            취소
          </button>
          <button type="submit" className="text-orange flex-1 p-10">
            확인
          </button>
        </div>
      </form>
    </div>
  );
};

function DeliveriesNew() {
  const STEP_SELECT_CORP = "STEP_SELECT_CORP";
  const STEP_ADDRESS = "STEP_ADDRESS";

  const [step, setStep] = useState(STEP_SELECT_CORP);

  // Step1 진행
  const handleAfterSubmitStep1 = () => {
    setStep(STEP_ADDRESS);
  };

  // 배송 추가 취소
  const handleCancel = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ close: true }));
    }
  };

  if (step === STEP_SELECT_CORP) {
    return (
      <StepSelectCorpComponent
        onAfterSubmit={handleAfterSubmitStep1}
        onCancel={handleCancel}
      />
    );
  } else if (step === STEP_ADDRESS) {
    return <StepAddressComponent onCancel={handleCancel} />;
  } else {
    return <></>;
  }
}

export default DeliveriesNew;
