/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import Modal from "../components/Modal";

import {
  useDeliveriesStore,
  useLoadingStore,
  useModalStore,
} from "../stores/hooks";

import { DELAY_REASONS, REASON_ETC } from "../utils/delay";

const ReasonItem = ({ active, reason, onSelect }) => {
  const handleSelect = () => {
    onSelect(reason);
  };

  return (
    <div>
      <label className="block mb-10" onClick={handleSelect}>
        <input
          type="radio"
          name="corp"
          defaultChecked={!!active}
          className="mr-5"
        />
        {reason.text}
      </label>
    </div>
  );
};

function DeliveryDelayModal({ delivery, onAfterSubmit }) {
  const { ...actions } = useDeliveriesStore();

  const { ...loadingActions } = useLoadingStore();

  const { closeModal } = useModalStore();

  const { register, handleSubmit, reset, setValue, watch } = useForm();

  const [load, setLoad] = useState(false);

  useEffect(() => {
    reset({
      code: DELAY_REASONS[0].code,
    });

    setLoad(true);
  }, []);

  // 배송 지연 취소
  const handleCancel = () => {
    closeModal();
  };

  // 사유 선택
  const handleSelectReason = (reason) => {
    setValue("code", reason.code);
    setValue("text", reason.text);
    setValue("etcText", "");
  };

  // 직접입력 전체 삭제
  const handleDeleteEtcText = () => {
    setValue("etcText", "");
  };

  const onSubmit = async ({ code, text, etcText }) => {
    try {
      loadingActions.startLoading();

      const reasonText = `${text}${
        code === REASON_ETC && etcText ? `(${etcText})` : ""
      }`;

      const data = {
        bookId: delivery.bookId,
        reason: reasonText,
        reasonType: code,
      };

      await actions.setDelay(data);

      onAfterSubmit();
      closeModal();

      // TODO @AKI 문자 발송 및 토스트 메시지 표기 방법 고민
      // if (window.ReactNativeWebView) {
      //   window.ReactNativeWebView.postMessage(
      //     JSON.stringify({ submitted: true, reasonText }),
      //   );
      // }
    } catch (e) {
      window.alert(`배송지연 사유 등록에 실패했습니다.. ${e.message}`);
    }

    loadingActions.finishLoading();
  };
  return (
    <Modal>
      <div className="p-15">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-20">
            <h5 className="font-medium text-18 mb-10">
              {delivery?.bookId}{" "}
              {delivery?.receiverAddress || delivery?.receiverAddressRoad || ""}{" "}
              배송지연 사유를 선택해주세요.
            </h5>
            <div>
              {load &&
                DELAY_REASONS.map((reason) => {
                  return (
                    <ReasonItem
                      key={reason.code}
                      active={reason.code === watch("code")}
                      reason={reason}
                      onSelect={handleSelectReason}
                    />
                  );
                })}
              <input name="code" {...register("code")} hidden />
              <input name="text" {...register("text")} hidden />
              <div className="bg-[#f6f6f6] p-5 flex justify-between items-center ml-10">
                <input
                  type="text"
                  className="bg-transparent max-w-[calc(100vw-120px)] flex-1"
                  {...register("etcText")}
                  disabled={watch("code") !== REASON_ETC}
                />
                <button
                  type="button"
                  className="text-[#999] p-5 w-50"
                  onClick={handleDeleteEtcText}
                >
                  삭제
                </button>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center">
            <button
              type="button"
              className="flex-1 p-10"
              onClick={handleCancel}
            >
              취소
            </button>
            <button type="submit" className="text-orange flex-1 p-10">
              확인
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default DeliveryDelayModal;
