/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";
import { atom, useRecoilState } from "recoil";

import api from "../Api";

import Delivery from "../../models/Delivery";

const deliveryAtom = atom({
  key: "delivery",
  default: null,
});

export const useDeliveriesStore = () => {
  const [delivery, setDelivery] = useRecoilState(deliveryAtom);

  const fetchById = useCallback(
    async (bookId) => {
      const response = await api.get(`/deliveries/${bookId}`);

      setDelivery(new Delivery(response));
    },
    [api.get],
  );

  /**
   * 배송 지연
   */
  const setDelay = useCallback(
    async ({ bookId, reason, reasonType }) => {
      return await api.put(`/deliveries/${bookId}/delay`, {
        reason,
        reasonType,
      });
    },
    [api.put],
  );

  /**
   * 분실 완료
   */
  const setLost = useCallback(
    async ({ bookId, reason, reasonType }) => {
      return await api.put(`/deliveries/${bookId}/lost`, {
        reason,
        reasonType,
      });
    },
    [api.put],
  );

  /**
   * 배송 연기
   */
  const setPostponed = useCallback(
    async ({ bookId }) => {
      return await api.put(`/deliveries/${bookId}/postponed`);
    },
    [api.put],
  );

  /**
   * 이전 상태로
   */
  const setPrevStatusBulk = useCallback(
    async ({ bookIds, status }) => {
      return await api.put(`/deliveries/prev`, { bookIds, status });
    },
    [api.put],
  );

  /**
   * 반송 완료
   */
  const setSentBack = useCallback(
    async ({ bookId, reason, reasonType }) => {
      return await api.put(`/deliveries/${bookId}/sent-back`, {
        reason,
        reasonType,
      });
    },
    [api.put],
  );

  /**
   * 배송 출발
   */
  const setStart = useCallback(
    async ({ bookId }) => {
      return await api.put(`/deliveries/${bookId}/delivery-start`);
    },
    [api.put],
  );

  const state = {
    delivery,
  };

  return {
    state,
    fetchById,
    setDelay,
    setLost,
    setPostponed,
    setPrevStatusBulk,
    setSentBack,
    setStart,
  };
};
