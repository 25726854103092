import React from "react";
import { Outlet } from "react-router-dom";

import LoadingComponent from "./Loading";

import { useLoadingStore, useModalStore } from "../stores/hooks";

function Layout() {
  const { loading } = useLoadingStore().state;
  const { modals } = useModalStore().state;

  return (
    <div className="min-h-screen">
      <Outlet />

      {loading && <LoadingComponent />}
      {modals?.map((modal) => modal)}
    </div>
  );
}

export default Layout;
