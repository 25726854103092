/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import Modal from "../components/Modal";

function DeliveriesNewAddressDetail({ address, onSubmit, onCancel }) {
  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    reset({ address });
  }, []);

  return (
    <Modal>
      <div className="p-15">
        <h2 className="font-medium text-18 mb-20">상세주소입력</h2>
        <div className="mb-10 text-16">{address}</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-10">
            <div>
              <div className="bg-[#f6f6f6] p-5 flex justify-between items-center">
                <input {...register("address")} className="hidden" />
                <input
                  type="text"
                  {...register("addressDetail")}
                  className="bg-transparent flex-1"
                />
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center">
            <button type="button" className="flex-1 p-10" onClick={onCancel}>
              취소
            </button>
            <button type="submit" className="text-orange flex-1 p-10">
              확인
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default DeliveriesNewAddressDetail;
