import { useCallback } from "react";
import { atom, useRecoilState } from "recoil";

const loadingState = atom({
  key: "loading",
  default: false,
});

export const useLoadingStore = () => {
  const [loading, setLoading] = useRecoilState(loadingState);

  const startLoading = useCallback(() => {
    setLoading(true);
  }, [setLoading]);

  const finishLoading = useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  const state = {
    loading,
  };

  return { state, startLoading, finishLoading };
};
