import React from "react";
import { useForm } from "react-hook-form";

import { useAuthStore } from "../stores/hooks";

function Password() {
  const { ...actions } = useAuthStore();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    const validEnglish = /[a-zA-Z]/g;
    const validNumber = /[0-9]/g;
    const validCharacter = /[!@#$%^&*()+=-_?/"':;~]/g;

    if (
      !data.newPassword.match(validEnglish) ||
      !data.newPassword.match(validNumber) ||
      !data.newPassword.match(validCharacter)
    ) {
      setError("newPassword", {
        type: "custom",
        message: "영문, 숫자, 특수문자 포함 8자 이상으로 설정해주십시오.",
      });
    } else {
      try {
        const response = await actions.updatePassword(data);

        if (response.success) {
          window.alert("비밀번호 변경에 성공했습니다.");
          reset();

          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({ updated: "updated" }),
            );
          }
        } else {
          window.alert("비밀번호 수정에 실패했습니다.");
        }
      } catch (e) {
        window.alert(e.message || "비밀번호 수정에 실패했습니다.");
      }
    }
  };

  return (
    <div className="bg-gradient-orange min-h-screen flex justify-center items-center">
      <form onSubmit={handleSubmit(onSubmit)} className="max-w-[280px] w-full">
        <div className="mb-10">
          <input
            {...register("password", {
              required: "현재 비밀번호를 입력해주세요.",
            })}
            className="w-full text-16 p-6"
            placeholder="현재 비밀번호"
          />
          <div className="text-white text-14 mt-2">
            {errors.password && errors.password.message}
          </div>
        </div>
        <div className="mb-10">
          <input
            {...register("newPassword", {
              required: "새 비밀번호를 입력해주세요.",
              minLength: {
                value: 8,
                message:
                  "영문, 숫자, 특수문자 포함 8자 이상으로 설정해주십시오.",
              },
            })}
            className="w-full text-16 p-6"
            placeholder="새 비밀번호"
          />
          <div className="text-white text-14 mt-2">
            {errors.newPassword && errors.newPassword.message}
          </div>
        </div>
        <button
          type="submit"
          className="bg-mint text-white w-full h-40 font-bold text-16"
        >
          비밀번호 변경
        </button>
        <p className="text-13 text-white mt-6">
          비밀번호는 영문, 숫자, 특수문자 3가지 조합 8자 이상으로 설정해야
          합니다.
        </p>
      </form>
    </div>
  );
}

export default Password;
