import { format } from "date-fns-tz";

export const formatDate = (
  date = new Date(),
  dateFormat = "yyyy-MM-dd",
  timeZone = "Asia/Seoul",
) => {
  return format(date, dateFormat, {
    timeZone,
  });
};
