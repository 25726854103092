export const REASON_REFUSAL = "REASON_REFUSAL";
export const REASON_MOBILE_ERROR = "REASON_MOBILE_ERROR";
export const REASON_ADDRESS_ERROR = "REASON_ADDRESS_ERROR";
export const REASON_NO_ENTRY = "REASON_NO_ENTRY";
export const REASON_FRAGILE = "REASON_FRAGILE";
export const REASON_MISDELIVERY = "REASON_MISDELIVERY";
export const REASON_ETC = "REASON_ETC";

export const SENT_BACK_REASONS = [
  {
    text: "수취 거부",
    code: REASON_REFUSAL,
  },
  {
    text: "연락처 오류/연락 불가",
    code: REASON_MOBILE_ERROR,
  },
  {
    text: "주소지 오류(수하인미거주)",
    code: REASON_ADDRESS_ERROR,
  },
  {
    text: "출입 불가 및 보관장소 없음",
    code: REASON_NO_ENTRY,
  },
  {
    text: "취급불가 화물",
    code: REASON_FRAGILE,
  },
  {
    text: "오배송반송",
    code: REASON_MISDELIVERY,
  },
  {
    text: "기타",
    code: REASON_ETC,
  },
];
