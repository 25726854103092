/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";
import { atom, useRecoilState } from "recoil";

import api, { riderId } from "../Api";

const csAtom = atom({
  key: "cs",
  default: null,
});

export const useCsesStore = () => {
  const [cs, setCs] = useRecoilState(csAtom);

  const fetchById = useCallback(async () => {
    if (riderId) {
      const response = await api.get(`/riders/${riderId}/allocated-deliveries`);

      setCs({});
    }
  }, [api.get, riderId]);

  const state = {
    cs,
  };

  return { state, fetchById };
};
