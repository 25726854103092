import React from "react";

function Loading() {
  return (
    <div className="z-[1000] fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-[rgba(0,0,0,0.1)] backdrop-blur-[10px]">
      <div className="bg-white rounded-8 w-120 h-120 flex justify-center items-center">
        <img src="/images/loading.gif" alt="로딩중" className="w-70" />
      </div>
    </div>
  );
}

export default Loading;
