/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from "react";

import {
  useCsesStore,
  useDeliveriesStore,
  useLoadingStore,
} from "../stores/hooks";

function Cs() {
  const params = new URLSearchParams(window.location.search);
  const deliveryId = params.get("deliveryId");

  const { cs } = useCsesStore().state;
  const { ...actions } = useCsesStore();

  const { delivery } = useDeliveriesStore().state;
  const { ...deliveriesActions } = useDeliveriesStore();

  const { ...loadingActions } = useLoadingStore();

  useEffect(() => {
    fetchDelivery();
    fetchCs();
  }, [deliveryId]);

  const fetchDelivery = useCallback(() => {}, []);
  const fetchCs = useCallback(() => {}, []);

  return <div className="p-10">어쩌구</div>;
}

export default Cs;
