export const REASON_ADDRESS_ERROR = "REASON_ADDRESS_ERROR";
export const REASON_BREAKAGE = "REASON_BREAKAGE";
export const REASON_ETC = "REASON_ETC";
export const REASON_LOST = "REASON_LOST";

export const LOST_REASONS = [
  {
    text: "배송위치 확인 불가(오배송 분실)",
    code: REASON_ADDRESS_ERROR,
  },
  {
    text: "배송 중 유실",
    code: REASON_LOST,
  },
  {
    text: "배송 중 파손",
    code: REASON_BREAKAGE,
  },
  {
    text: "기타",
    code: REASON_ETC,
  },
];
