import BaseModel from "./base-model";
import { formatDate } from "../lib/date";

export default class History extends BaseModel {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  get displaySenderAddress() {
    if (this.senderAddress) {
      return this.senderAddress.split(" ").slice(2).join(" ");
    } else if (this.senderAddressRoad) {
      return this.senderAddressRoad.split(" ").slice(2).join(" ");
    } else {
      return "";
    }
  }
  get displayReceiverAddress() {
    if (this.receiverAddress) {
      return this.receiverAddress.split(" ").slice(2).join(" ");
    } else if (this.receiverAddressRoad) {
      return this.receiverAddressRoad.split(" ").slice(2).join(" ");
    } else {
      return "";
    }
  }

  get receiverDongName() {
    return this.receiverDongObj?.name || "";
  }

  get senderDongName() {
    return this.senderDongObj?.name || "";
  }

  get spotFee() {
    return this.spot?.fee || 0;
  }

  renderPickupDateScheduled(dateFormat = "yyMMdd") {
    return (
      this.pickupDateScheduled &&
      formatDate(this.pickupDateScheduled, dateFormat)
    );
  }

  renderReleasedAt(dateFormat = "yyMMdd") {
    return this.releasedAt && formatDate(this.releasedAt, dateFormat);
  }
}
