/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import { useAuthStore, useLoadingStore } from "../stores/hooks";
import { PUSH_NOTIFICATION } from "../stores/Auth";

const ToggleComponent = ({ type, active }) => {
  const { ...actions } = useAuthStore();
  const { ...loadingActions } = useLoadingStore();

  const handleClick = async () => {
    try {
      loadingActions.startLoading();

      await actions.updatePushNotification({
        type,
        state: !active,
      });
    } catch (e) {
      window.alert(
        `푸시 알림 설정에 실패했습니다. 다시 시도해주세요. ${e.message}`,
      );
    }

    loadingActions.finishLoading();
  };

  return (
    <div
      className={`w-60 bg-white border-1 h-30 rounded-full relative cursor-pointer ${
        active ? "border-orange" : "border-[#ddd]"
      }`}
      onClick={handleClick}
    >
      <div
        className={`absolute top-2 w-24 h-24 rounded-full ${
          active ? "right-2 bg-orange" : "left-2 bg-[#ddd]"
        }`}
      />
    </div>
  );
};

function SettingPush() {
  const { state, ...actions } = useAuthStore();
  const { ...loadingActions } = useLoadingStore();

  useEffect(() => {
    fetchPushNotification();
  }, []);

  const fetchPushNotification = async () => {
    try {
      loadingActions.startLoading();

      await actions.fetchPushNotification();
    } catch (e) {
      window.alert(
        `푸시 알림설정 정보 조회에 실패했습니다. 다시 시도해주세요. ${e.message}`,
      );
    }

    loadingActions.finishLoading();
  };

  return (
    <div className="p-20">
      <div className="flex justify-between items-center mb-10">
        <label className="text-16">수거 지정</label>
        <ToggleComponent
          type={PUSH_NOTIFICATION.PICKUP_ALLOCATED}
          active={state.pushNotification[PUSH_NOTIFICATION.PICKUP_ALLOCATED]}
        />
      </div>
      <div className="flex justify-between items-center mb-10">
        <label className="text-16">배송 배차</label>
        <ToggleComponent
          type={PUSH_NOTIFICATION.DELIVERY_ALLOCATED}
          active={state.pushNotification[PUSH_NOTIFICATION.DELIVERY_ALLOCATED]}
        />
      </div>
      <div className="flex justify-between items-center mb-10">
        <label className="text-16">재배차 등록(나→다른라이더)</label>
        <ToggleComponent
          type={PUSH_NOTIFICATION.REALLOCATED_DELIVERY_START_TO_OLD_RIDER}
          active={
            state.pushNotification[
              PUSH_NOTIFICATION.REALLOCATED_DELIVERY_START_TO_OLD_RIDER
            ]
          }
        />
      </div>
      <div className="flex justify-between items-center mb-10">
        <label className="text-16">재배차 등록(다른라이더→나)</label>
        <ToggleComponent
          type={PUSH_NOTIFICATION.REALLOCATED_DELIVERY_START_TO_NEW_RIDER}
          active={
            state.pushNotification[
              PUSH_NOTIFICATION.REALLOCATED_DELIVERY_START_TO_NEW_RIDER
            ]
          }
        />
      </div>
    </div>
  );
}

export default SettingPush;
