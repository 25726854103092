/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";

import {
  TERM_1DAY,
  TERM_7DAY,
  TERM_30DAY,
  TYPE_DELIVER_COMPLETED,
  TYPE_PICKUP_COMPLETED,
} from "../stores/History";
import { useHistoryStore, useLoadingStore } from "../stores/hooks";

const Item = ({ delivery, type }) => {
  return (
    <tr>
      <Td>
        {type === TYPE_DELIVER_COMPLETED
          ? delivery.renderReleasedAt()
          : delivery.renderPickupDateScheduled()}
      </Td>
      <Td textAlign="text-left">
        (출)
        {delivery.displaySenderAddress}
        <br />
        (도)
        {delivery.displayReceiverAddress}
      </Td>
      <Td>{type === TYPE_DELIVER_COMPLETED ? "배송" : "수거"}</Td>
      <Td>{delivery.spotFee}</Td>
    </tr>
  );
};
const Th = ({ children }) => {
  return (
    <th className="border-b-1 border-b-[#ddd] py-5 text-18 font-medium">
      {children}
    </th>
  );
};

const Td = ({ textAlign, children, ...rest }) => {
  return (
    <td
      className={`border-b-1 border-b-[#ddd] py-5 text-16 ${
        textAlign || "text-center"
      }`}
      {...rest}
    >
      {children}
    </td>
  );
};

function History() {
  const { state, ...actions } = useHistoryStore();
  const { ...loadingActions } = useLoadingStore();
  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    if (state.query?.page) {
      reset({
        term: state.query.term,
        type: state.query.type,
      });

      fetchAll();
    }
  }, [state.query]);

  const onSubmit = (data) => {
    actions.setQuery({
      page: 1,
      ...data,
    });
  };

  const nextPage = () => {
    actions.setQuery({
      page: state.query.page + 1,
    });
  };

  const fetchAll = useCallback(async () => {
    try {
      loadingActions.startLoading();

      await actions.fetchAll();
    } catch (e) {
      window.alert(`수거/배송 내역 조회에 실패했습니다. ${e?.message}`);
    }

    loadingActions.finishLoading();
  }, [actions.fetchAll]);

  return (
    <div className="p-10">
      <div className="mb-10">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex justify-between items-center"
        >
          <div className="flex-1">
            <div className="flex justify-start items-center mb-5">
              <label className="w-[33.33%]">
                <input
                  type="radio"
                  name="term"
                  value={TERM_1DAY}
                  className="mr-5"
                  {...register("term")}
                />
                1일
              </label>
              <label className="w-[33.33%]">
                <input
                  type="radio"
                  name="term"
                  value={TERM_7DAY}
                  {...register("term")}
                />
                7일
              </label>
              <label className="w-[33.33%]">
                <input
                  type="radio"
                  name="term"
                  value={TERM_30DAY}
                  className="mr-5"
                  {...register("term")}
                />
                30일
              </label>
            </div>
            <div className="flex justify-start items-center">
              <label className="w-[33.33%]">
                <input
                  type="radio"
                  name="type"
                  value={TYPE_PICKUP_COMPLETED}
                  className="mr-5"
                  {...register("type")}
                />
                수거
              </label>
              <label className="w-[33.33%]">
                <input
                  type="radio"
                  name="type"
                  value={TYPE_DELIVER_COMPLETED}
                  className="mr-5"
                  {...register("type")}
                />
                배송
              </label>
            </div>
          </div>
          <button
            type="submit"
            className="bg-orange text-white w-60 text-16 font-bold rounded-6 h-60"
          >
            조회
          </button>
        </form>
      </div>
      <table className="w-full border-t-1 border-t-[#ddd]">
        <thead>
          <tr>
            <Th>지정일</Th>
            <Th>주소</Th>
            <Th>담당</Th>
            <Th>운임</Th>
          </tr>
        </thead>
        <tbody>
          {state.histories?.map((delivery, index) => {
            return (
              <Item key={index} delivery={delivery} type={state.query?.type} />
            );
          })}
          {state.histories.length === 0 && (
            <tr>
              <Td colSpan={4}>목록이 없습니다.</Td>
            </tr>
          )}
        </tbody>
      </table>
      {state.query?.page < state.pageCount && (
        <button
          className="bg-orange text-white text-16 font-bold rounded-6 w-full p-4 mt-10"
          type="button"
          onClick={nextPage}
        >
          더보기
        </button>
      )}
    </div>
  );
}

export default History;
